<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Fees profile') }}
    </p>
    <section class="content-margin">
      <div class="d-flex align-items-center mb-15 px-7 filter-block">
        <label>{{ $t('Select a category') }}</label>
        <vue-select
          :options="profile.options"
          class="input cus-select profile-dropdown"
          v-model="profile.selected"
          @input="changeProfile"
        ></vue-select>
        <button class="primary-btn btn-limit-width bg-yellow" @click="openNewProfileModal">
          {{ $t('New profile') }}
        </button>
      </div>

      <div class="mb-20 px-7" v-if="selectedProfile">
        <label>{{ $t('Approved for use for') }}</label>
        <vue-select
          :options="roles.options"
          class="input cus-select cus-multi-dropdown"
          v-model="roles.selected"
          multiple
        ></vue-select>
      </div>

      <div class="px-7 mb-15" v-if="selectedProfile">
        <div class="fee-block d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_default" id="is_default"></v-checkbox>
            <label class="mx-5" for="is_default">{{ $t('As a default profile') }}</label>
          </div>
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_shaniv_default" id="is_shaniv_default"></v-checkbox>
            <label class="mx-5" for="is_shaniv_default">{{ $t('As shaniv default') }}</label>
          </div>

          <button class="primary-btn primary-btn_sm mx-15" @click="saveProfileFlags">
            {{ $t('Save') }}
          </button>
        </div>
      </div>

      <div class="d-flex flex-wrap" v-if="selectedProfile">
        <div class="w-25 form-div px-7 mb-10">
          <label>{{ $t('Select a supplier') }}</label>
          <vue-select
            :options="suppliersList.options"
            class="input cus-select"
            v-model="suppliersList.selected"
            @input="changeSuppliersList"
          ></vue-select>
        </div>
      </div>

      <div class="d-flex mt-25 flex-wrap" v-if="selectedProfile">
        <div class="w-25 px-7">
          <label>{{ $t('Profit commission to the marketer') }}</label>
          <input type="text" class="input" v-model="productProfit">
        </div>
        <div class="w-50 px-7">
          <label></label>
          <button class="primary-btn"  @click="applyProfit" v-if="productProfit.length">
            {{ $t('Apply a profit commission on all products') }}
          </button>
          <button class="primary-btn" v-else disabled>
            {{ $t('Apply a profit commission on all products') }}
          </button>
        </div>
      </div>

      <div class="d-flex mt-10 flex-wrap" v-if="selectedProfile">
        <div class="w-25 px-7">
          <label>{{ $t('Extra charge to the consumer') }}</label>
          <input type="text" class="input" v-model="productPoint">
        </div>
        <div class="w-50 px-7">
          <label></label>
          <button class="primary-btn" @click="applyPoint" v-if="productPoint.length">
            {{ $t('Apply an additional price to the consumer') }}
          </button>
          <button class="primary-btn" v-else disabled>
            {{ $t('Apply an additional price to the consumer') }}
          </button>
        </div>
      </div>
    </section>

    <section class="table-content-part mt-20" v-if="selectedProfile">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="_headers"
        :search="search"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
        <template v-for="h in _headers" v-slot:[`header.${h.value}`]="{ }">
          <div :key="h.value" class="d-flex align-items-center flex-column">
            <span>{{h.text}}</span>
            <v-checkbox 
              color="#0D3856" 
              class="cus-checkbox" 
              v-if="h.value=='is_authorized'"
              v-model="enabledAll"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.supplier_id`]="{ item }">
          <div style="display: flex; justify-content: center;">
            {{ item.Product.supplier_id }}
          </div>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <div style="display: flex; justify-content: center;">
            {{ item.Product.ProductDetails[0].name }}
          </div>
        </template>
        <template v-slot:[`item.is_authorized`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.is_authorized"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.final_commission`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.final_commission">
          </div>
        </template>
        <template v-slot:[`item.business_commission`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.business_commission">
          </div>
        </template>
        <template v-slot:[`item.points`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.points">
          </div>
        </template>
      </v-data-table>
    </section>

    <div class="content-margin mb-20 d-flex mt-20" v-if="selectedProfile">
      <button class="primary-btn btn-limit-width" @click="updateProfile">
        {{ $t("Save") }}
      </button>
      <button class="primary-btn btn-limit-width bg-red1 mx-10" @click="openDeleteConfirm">
        {{ $t("Delete") }}
      </button>
    </div>

    <modal
      :width="550"
      :adaptive="true"
      class="newProfileModal CEModal modal-scroll-bar"
      name="newProfileModal"
    >
      <div class="modal-content">
        <div class="validation-form-group"
          :class="{ 'form-group--error': $v.newProfile.$error }">
          <label>{{ $t('Profile Name') }} *</label>
          <input
            type="text" 
            class="input"
            v-model="newProfile"
            @input="$v.newProfile.$touch"
          />
          <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newProfile.required">
            {{ $t('This is a required field.') }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="primary-btn primary-btn_sm mx-5" @click="saveNewProfile">
          {{ $t('Confirm') }}
        </button>
        <button class="primary-btn primary-btn_sm mx-5 bg-2" @click="closeModal">
          {{ $t('Cancel') }}
        </button>
      </div>
    </modal>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content v-if="isDeleteConfirm">
        {{ $t('Are you sure you want to delete {NAME}?', {NAME: selectedProfile ? selectedProfile.name : ""}) }}
      </template>
      <template v-slot:modal-content v-else>
        {{ $t('This action will lead to changes in all products, including those that not shown after filtration! Are you sure you want to continue?') }}
      </template>
    </ConfirmModal>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>  
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmModal from "../../../components/management/ConfirmModal.vue"
export default {
  name: 'CPCommissionsProfile',
  mixins: [validationMixin],
  components: {
    ConfirmModal
  },
  data() {
    return {
      profile: {
        options: [],
        selected: ""
      }, 
      roles: {
        options: [],
        selected: []
      }, 
      newProfile: "",
      suppliersList: {
        options: [],
        selected: ""
      },
      tableData: [],
      search: '',
      productProfit: "",
      productPoint: "",
      isDeleteConfirm: false,
      is_default: false,
      is_shaniv_default: false,
    }
  },
  validations: {
    newProfile: {
      required
    }
  },
  computed: {
    ...mapState({
      commissionsProfile: (state) => state.category_products.commissionsProfile,
      commissionsProfileData: (state) => state.category_products.commissionsProfileData,
      rolesList: (state) => state.seller_tourism_percentage.rolesList,
      supplier_items: (state) => state.supplier.supplier_items,
      loading1: (state) => state.seller_tourism_percentage.loading,
      loading2: (state) => state.supplier.loading,
    }),
    loading() {
      return this.loading1 || this.loading2;
    },
    enabledAll: {
      get: function () {
        const checkedLength = this.tableData.filter(function(item) {
          return item.is_authorized==false;
        }).length;
        if (checkedLength) {
          return false
        } 
        return true;
      },
      set: function (value) {
        this.tableData.forEach(item => {
          item.is_authorized = value;
        })
      }
    },
    _headers () {
      let headerArray = [];
      const headerFront = [
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Product Name'),
          align: 'center',
          sortable: false,
          value: 'product_name',
        },
        {
          text: this.$t('Addition to the consumer'),
          align: 'center',
          sortable: false,
          value: 'final_commission',
        },
        {
          text: this.$t("Commissioner's commission"),
          align: 'center',
          sortable: false,
          value: 'business_commission',
        },
        {
          text: this.$t('Points'),
          align: 'center',
          sortable: false,
          value: 'points',
        },
      ]

      const headerBack = [
        {
          text: this.$t('Enabled'),
          align: 'center',
          sortable: false,
          value: 'is_authorized'
        }
      ]

      headerArray = [...headerFront, ...headerBack];
      return headerArray;
    },
    selectedProfile() {
      let selectedProfile = null;

      if (this.commissionsProfileData && this.commissionsProfileData.length > 0
        && this.profile && this.profile.selected && this.profile.selected.code === this.commissionsProfileData[0].id) {
        selectedProfile = this.commissionsProfileData[0];
      }

      return selectedProfile;
    }
  },
  async created() {
    if (this.commissionsProfile.length == 0) {
      await this.getCommissionsProfile();
    }
    if (this.commissionsProfile.length != 0) {
      await this.createProfileDropdown();
    }
    if (this.rolesList.length == 0) {
      await this.getRoles();
    }
    if (this.rolesList.length != 0) {
      this.roles = {
        options: [],
        selected: ""
      };
      this.rolesList.forEach(el => {
        this.roles.options.push({
          label: el.name,
          code: el.id,
        });
      });

      this.roles.selected = [];
    }

    if (Object.keys(this.supplier_items).length == 0) {
      await this.getAllSupplierItems();
    }
    if (Object.keys(this.supplier_items).length != 0) {
      for (const key in this.supplier_items) {
        this.supplier_items[key].forEach(el => {
          this.suppliersList.options.push({
            label: el.name,
            code: el.id,
          });
        });
      }
    }

    this.changeProfile();
  },
  methods: {
    ...mapActions("category_products", {
      getCommissionsProfile: "getCommissionsProfile",
      getCommissionsProfileData: "getCommissionsProfileData",
      createProfitProfile: "createProfitProfile",
      updateProductProfit: "updateProductProfit",
      updateProfitProfile: "updateProfitProfile",
      deleteCommissionProfile: "deleteCommissionProfile"
    }),
    ...mapActions("seller_tourism_percentage", {
      getRoles: "getRoles",
      updateProfileFlags: "updateProfileFlags"
    }),
    ...mapActions("supplier", {
      getAllSupplierItems: "getAllSupplierItems"
    }), 
    async createProfileDropdown(id = null) {
      this.profile = {
        options: [],
        selected: ""
      };
      this.commissionsProfile.forEach(el => {
        this.profile.options.push({
          label: el.name,
          code: el.id,
        });
      });

      if (this.profile.options && this.profile.options.length > 0) {
        let profileToSelect = this.profile.options[0];
        if (id) {
          profileToSelect = this.profile.options.find(p => p.code === id) || profileToSelect;
        }
        this.profile.selected = {...(profileToSelect)};
        this.profile = {...(this.profile)};
      }
      await this.changeProfile();
    },
    async clearForm() {
      this.roles.selected = [];
      this.tableData = [];
      this.is_default = false;
      this.is_shaniv_default = false;
      this.search = "";
      this.productProfit = "";
      this.productPoint = "";
      this.suppliersList.selected = "";
    },
    async changeProfile() {
      await this.clearForm();
      if (!this.profile || !this.profile.selected || !this.profile.selected.code) {
          return;
      }
      let profile_id = this.profile.selected.code;
      await this.getCommissionsProfileData({
        id: profile_id
      });
      if (this.selectedProfile) {
        if (this.selectedProfile.Roles && this.selectedProfile.Roles.length > 0) {
          this.selectedProfile.Roles.forEach(el => {
            this.roles.selected.push({
              code: el.BusinessRole.role_id,
              label: el.name
            });
          });
        }
        this.tableData = this.selectedProfile.BusinessProduct;
        this.is_default = this.selectedProfile.is_default;
        this.is_shaniv_default = this.selectedProfile.is_shaniv_default;
      }
    },
    openNewProfileModal() {
      this.$modal.show('newProfileModal');
    },
    closeModal() {
      this.$modal.hide('newProfileModal');
      this.newProfile = "";
    },
    saveNewProfile() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }
      this.createProfitProfile({
        name: this.newProfile,
        profitModle: "commission"
      }).then(async res => {
        this.closeModal();
        if (res.message == "success") {
          let createdProfileId = res && res.id ? res.id : null;
          await this.getCommissionsProfile();
          await this.createProfileDropdown(createdProfileId);
        }
      })
    },
    changeSuppliersList() {
      this.tableData = [];
      const supplier_id = this.suppliersList && this.suppliersList.selected
        ? this.suppliersList.selected.code : null;
      if (this.selectedProfile && this.selectedProfile.BusinessProduct) {
        this.tableData = this.selectedProfile.BusinessProduct.filter((el) => {
          return el.Product.supplier_id == supplier_id;
        });
      }
    },
    applyProfit() {
      this.isProfitConfirm = true;
      this.isDeleteConfirm = false;
      this.$modal.show('confirmModal');
    },
    applyPoint() {
      this.isProfitConfirm = false;
      this.isDeleteConfirm = false;
      this.$modal.show('confirmModal');
    },
    async confirmData() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      if (this.isDeleteConfirm) {
        await this.deleteCommissionProfile(profile_id);
        await this.getCommissionsProfile();
        await this.createProfileDropdown();
      } else {
        let product_id = [];
        this.tableData.forEach((el) => {
          product_id.push(el.product_id);
        });
        let supplier_id = null;
        if (this.suppliersList.selected != "") {
          supplier_id = this.suppliersList.selected.code;
        }
        let params = {};
        if (this.isProfitConfirm) {
          params = {
            business_commission: this.productProfit,
            product_id: product_id,
            profile_id: profile_id,
            supplier_id: supplier_id,
            type: "commission"
          }
        } else {
          params = {
            final_commission: this.productPoint,
            product_id: product_id,
            profile_id: profile_id,
            supplier_id: supplier_id,
            type: "finalCommission"
          }
        }
  
        await this.updateProductProfit(params);
        this.productPoint = "";
        this.productProfit = "";
        this.changeProfile();
      }

      this.$modal.hide('confirmModal');
    },
    updateProfile() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      let tempRoles = [];
      this.roles.selected.forEach(el => {
        tempRoles.push(el.label);
      });
      let tempProducts_list = [];
      if (this.selectedProfile && this.selectedProfile.BusinessProduct) {
        this.selectedProfile.BusinessProduct.forEach(el => {
          tempProducts_list.push({
            business_commission: el.business_commission,
            final_commission: el.final_commission,
            is_authorized: el.is_authorized,
            product_id: el.product_id
          });
        });
      }
      let params = {
        products_list: tempProducts_list,
        profile_id: profile_id,
        roles: tempRoles,
        is_default: !!this.is_default,
        is_shaniv_default: !!this.is_shaniv_default
      };
      this.updateProfitProfile(params);
    },
    openDeleteConfirm() {
      if (!this.selectedProfile) {
        return;
      }
      this.isDeleteConfirm = true;
      this.$modal.show('confirmModal');
    },
    async saveProfileFlags() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      const params = {
        business_id: profile_id,
        is_default: !!this.is_default,
        is_shaniv_default: !!this.is_shaniv_default
      };
      await this.updateProfileFlags({params: params});

      this.changeProfile();
    },
  }
}
</script>

<style lang="scss" scoped>
label {
  height: 27px;
  display: block;
}

.fee-block {
  background: #E5E5E5;
  border: 1px solid #D4D4D4;
  padding: 10px;
  label {
    height: unset;
  }
  // .default-profile {
  //   margin-right: 50px;
  // }
}

.newProfileModal {
  .modal-footer {
    padding: 0 40px 30px;
  }
}

@media screen and (max-width: 635px) {
  .filter-block {
    flex-wrap: wrap;
    button {
      margin-top: 10px;
    }
  }
  .primary-btn {
    height: unset;
    min-height: 44px;
  }
  .w-25,
  .w-50,
  .w-75 {
    width: 100% !important;
  }
}
</style>