<template>
  <modal
    :width="550"
    :adaptive="true"
    class="confirmModal CEModal modal-scroll-bar"
    :name="name"
  >
    <div class="modal-content">
      <slot name="modal-content"></slot>
    </div>
    <div class="modal-footer">
      <button class="primary-btn primary-btn_sm mx-5" @click="confirmHandler">
        {{ $t('Confirm') }}
      </button>
      <button class="primary-btn primary-btn_sm mx-5 bg-2" @click="closeModal">
        {{ $t('Cancel') }}
      </button>
    </div>
  </modal>  
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    name: {
      type: String,
      default: 'confirmModal'
    },
  },
  methods: {
    confirmHandler() {
      this.$emit('confirmData');
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
  }

}
</script>

<style lang="scss" scoped>
  .confirmModal {
    .modal-content {
      font-size: 16px;
      padding: 30px;
    }
    .modal-footer {
      justify-content: flex-end;
      padding: 10px;
      border-top: 1px solid #dedede;
    }
    .primary-btn {
      width: auto;
      padding: 0 20px;
    }
  }
</style>